import React, { useState } from 'react';

import frame from '../../img/TradingInstruments/frame.png'
import frame2 from '../../img/TradingInstruments/frame2.png'
import frame3 from '../../img/TradingInstruments/oil.png'
import frame4 from '../../img/TradingInstruments/Stocks.png'
import frame5 from '../../img/TradingInstruments/Bonds.png'
import component from '../../img/TradingInstruments/component.png'

import metal1 from '../../img/TradingInstruments/gold.png'
import metal2 from '../../img/TradingInstruments/silver.png'
import metal3 from '../../img/TradingInstruments/platinum.png'
import Footer from '../Footer';

import { useTranslation } from "react-i18next";

const data = [
    { 
        index: 0,
        title: 'tradinginstruments.11',
        text: 'tradinginstruments.12',
        img: frame
    },
    { 
        index: 1,
        title: 'tradinginstruments.13',
        text: 'tradinginstruments.14',
        img: frame2
    },
    { 
        index: 2,
        title: 'tradinginstruments.15',
        text: 'tradinginstruments.16',
        img: frame3
    },
    { 
        index: 3,
        title: 'tradinginstruments.17',
        text: 'tradinginstruments.18',
        img: frame4
    },
    { 
        index: 4,
        title: 'tradinginstruments.19',
        text: 'tradinginstruments.20',
        img: frame5
    },
];

function Switcher () {

    const [activeIndex, setActiveIndex] = useState(0);

    const handleButtonClick = (index) => {
        setActiveIndex(index);
    };

    const { t } = useTranslation();

    return(
        <div className="bg-[#000C17] max-w-[1280px] mx-auto relative z-30">
            <img src={data[activeIndex].img} alt="" onLoad={(e) => e.target.classList.add('opacity-100')} onError={(e) => e.target.classList.add('opacity-100')} className={`absolute left-1/2 -translate-x-1/2 transition-opacity duration-1500 ease-in-out opacity-0 ${activeIndex === 0 ? 'mt-0' : 'mt-[300px] xl:mt-[100px]'}`}/>
            <div className="left-blur"></div>
            <div className="right-blur"></div>
            <div className='max-w-[1280px] mx-auto mt-[100px] xl:mt-[200px] z-[999] relative'>
                <div className='xl:h-[70px] rounded-[20px] w-auto bg-[#03192F] flex flex-wrap items-center justify-between px-[10px] mb-[40px] xl:mb-0 xl:px-[80px] mx-[20px] xl:mx-0'>
                    {data.map((product) => (
                        <p 
                            onClick={() => handleButtonClick(product.index)} 
                            className={`py-[10px] xl:py-0 cursor-pointer uppercase russo-one text-[14px] xl:text-[20px] transition-all ${activeIndex === product.index ? 'text-[#00FFF0]' : 'text-[#00FFF04D]'}`}
                        >
                            {t(product.title)}
                        </p>
                    ))}
                </div>
                {activeIndex === 0 ? (          
                    <div className='flex flex-col xl:flex-row justify-between mt-[20px] xl:mt-[60px] mx-[20px] xl:mx-0'>
                        <div>
                            <p className='text-white russo-one text-[20px] xl:text-[32px] uppercase'>{t(data[activeIndex].title)}</p>
                            <p className='text-white max-w-[600px] mt-[20px] roboto text-[14px] xl:text-[16px] font-[300]'>{t(data[activeIndex].text)}</p>
                        </div>
                        <div>
                            <img src={component} alt="" className='moving-iceberg'/>
                        </div>
                    </div>
                ) : (<></>)}
                {activeIndex === 1 ? (
                    <div className='bg-[#000C17] xl:bg-transparent'>          
                        <div className='flex flex-col xl:flex-row justify-between mt-[20px] xl:mt-[60px] mx-[20px] xl:mx-0'>
                            <div>
                                <p className='text-white russo-one text-[20px] xl:text-[32px] uppercase'>{t(data[activeIndex].title)}</p>
                                <p className='text-white max-w-[600px] mt-[20px] roboto text-[14px] xl:text-[16px] font-[300]'>{t(data[activeIndex].text)}</p>
                            </div>
                        </div>
                        <div className='flex flex-wrap gap-[50px] mt-[50px]'>
                            <div className='onhover flex-grow-[1] w-[369px] h-[372px] mx-[20px] xl:mx-0 bg-[#03192F] rounded-[20px] relative overflow-hidden'>
                                <div>
                                    <img src={metal1} alt="" className='absolute bottom-[-20px] left-0 imghover transition-all'/>
                                    <p className='russo-one uppercase text-[20px] text-[#00FFF0] mt-[80px] text-center blockhover'>{t('tradinginstruments.2')}</p>
                                </div>
                                <div className='blockhoveron relative transition-all'>
                                    <p className='russo-one uppercase text-[20px] text-[#00FFF0] mt-[40px] ml-[40px]'>{t('tradinginstruments.3')}</p>
                                    <p className='mx-[40px] font-[300] roboto text-white mt-[20px] text-[16px] leading-[1.5]'>{t('tradinginstruments.4')}</p>
                                </div>       
                            </div>
                            <div className='onhover flex-grow-[1] w-[369px] h-[372px] mx-[20px] xl:mx-0 bg-[#03192F] rounded-[20px] relative overflow-hidden flex justify-center'>
                                <div>
                                    <img src={metal2} alt="" className='absolute bottom-[-20px] right-0 imghover transition-all'/>
                                    <p className='russo-one uppercase text-[20px] text-[#00FFF0] mt-[80px] blockhover'>{t('tradinginstruments.5')}</p>
                                </div>
                                <div className='blockhoveron relative transition-all'>
                                    <p className='russo-one uppercase text-[20px] text-[#00FFF0] mt-[40px] ml-[40px]'>{t('tradinginstruments.6')}</p>
                                    <p className='mx-[40px] font-[300] roboto text-white mt-[20px] text-[16px] leading-[1.5]'>{t('tradinginstruments.7')}</p>
                                </div>      
                            </div>
                            <div className='onhover flex-grow-[1] w-[369px] h-[372px] mx-[20px] xl:mx-0 bg-[#03192F] rounded-[20px] relative overflow-hidden flex justify-center'>
                                <div>
                                    <img src={metal3} alt="" className='absolute bottom-[-20px] right-0 imghover transition-all'/>
                                    <p className='russo-one uppercase text-[20px] text-[#00FFF0] mt-[80px] blockhover'>{t('tradinginstruments.8')}</p>
                                </div>
                                <div className='blockhoveron relative transition-all'>
                                    <p className='russo-one uppercase text-[20px] text-[#00FFF0] mt-[40px] ml-[40px]'>{t('tradinginstruments.9')}</p>
                                    <p className='mx-[40px] font-[300] roboto text-white mt-[20px] text-[16px] leading-[1.5]'>{t('tradinginstruments.10')}</p>
                                </div>      
                            </div>
                        </div>
                    </div>
                ) : (<></>)}
                {(activeIndex === 2 || activeIndex === 3 || activeIndex === 4) ? (          
                    <div className='flex flex-col xl:flex-row justify-between mt-[20px] xl:mt-[60px] mx-[20px] xl:mx-0'>
                        <div>
                            <p className='text-white russo-one text-[20px] xl:text-[32px] uppercase'>{t(data[activeIndex].title)}</p>
                            <p className='text-white max-w-[600px] mt-[20px] roboto text-[14px] xl:text-[16px] font-[300]'>{t(data[activeIndex].text)}</p>
                        </div>
                    </div>
                ) : (<></>)}
            </div>
            <Footer/>
        </div>
    );
}

export default Switcher;
import React from 'react';

import Fade from 'react-reveal/Fade';

import main from '../../img/TradingInstruments/main1.jpg'
import water from '../../img/TradingInstruments/water.png'
import iceberg from '../../img/TradingInstruments/iceberg.png'

import money1 from '../../img/TradingInstruments/money1.png'
import money2 from '../../img/TradingInstruments/money2.png'
import money3 from '../../img/TradingInstruments/money3.png'

import { useTranslation } from "react-i18next";


function Main () {

    const { t } = useTranslation();

    return(
        <div className="bg-[#000C17] max-w-[1920px] mx-auto relative">
            <div className='max-w-[1280px] xl:mx-auto mx-[20px] relative'>
                <div className='absolute left-0 mt-[130px] xl:mt-[200px]'>
                    <Fade left>
                        <p className='max-w-[800px] text-[24px] xl:text-[40px] russo-one text-white'>
                            {t('tradinginstruments.1')}
                        </p>
                    </Fade>
                </div>
            </div>
            <img src={main} alt="" className='object-cover w-full h-[720px]'/>
            <img src={water} alt=""  className='absolute h-[130px] xl:h-[200px] bottom-[-80px] z-50 w-full'/>
            <div className='moving-iceberg absolute overflow-hidden z-10 bottom-0 md:bottom-[-50px] xl:bottom-[50px] xl:right-[50px]'>
                <Fade bottom>
                    <img src={iceberg} alt="" />
                </Fade>
            </div>
            <div className='absolute bottom-[100px] scale-50 xl:scale-100 xl:right-[550px] xl:top-[250px] z-0'>
                <Fade left top>
                    <img src={money1} alt="" />
                </Fade>
            </div>
            <div className='absolute bottom-[80px] right-0 scale-50 xl:scale-100 xl:right-[350px] xl:top-[450px] z-20'>
                <Fade bottom right>
                    <img src={money2} alt="" />
                </Fade>
            </div>
            <div className='moving-block1 absolute bottom-[150px] right-1/3 scale-50 xl:scale-100 xl:right-[250px] xl:top-[250px] z-0'>
                <Fade top right>
                    <img src={money3} alt="" />
                </Fade>
            </div>
            
        </div>
    );
}

export default Main;